// React
import { ReactElement } from 'react';
// Modules
import classNames from 'classnames/bind';
// Design System
import { Font } from '@utmb/design-system/text/font';
import { Image } from '@utmb/design-system/image/Image';
import { Link } from '@utmb/design-system/link/link';
import { Pin } from '@utmb/design-system/pin/Pin';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
// Types
import { LinkPositionEnum } from '@utmb/types/enum/LinkPositionEnum';
import { Slice } from '@utmb/types/slice-handler';
import { StyleBannerEnum } from '@utmb/types/enum/StyleBannerEnum';
import SliceBannerDiscoverView from '@utmb/types/view/SliceBannerDiscoverView';
import { HorizontalAlignEnum, SliceRaceBannerView } from '@utmb/types/index';
// Utils
import { computeColorsFromBackgroundColor } from '@utmb/utils/colors';
import { parseMarkdown } from '@utmb/utils/markdown';
// Styles
import styles from './banner-discover.module.scss';
import { useGlobal } from '@utmb/contexts/GlobalContext';

const cx = classNames.bind(styles);

export const BannerDiscover = (props: Slice<SliceBannerDiscoverView & SliceRaceBannerView>): ReactElement => {
    const { id, title, content, link, linkPosition, mainColor, pictureAlign, picto, isDark, picture, style, prefix } = props;

    const {
        global: {
            event: { primaryColorIsDark, secondaryColorIsDark },
        },
    } = useGlobal();

    const { backgroundColor, buttonBgColor, buttonColor, textColor, titleColor } = computeColorsFromBackgroundColor(
        mainColor,
        isDark ?? false,
        primaryColorIsDark,
        secondaryColorIsDark,
        linkPosition
    );

    const supraLabelClasses = ['lh-20 lh-d-32 ls-1 font-uppercase', styles.rb_prefix].filter(Boolean).join(' ');

    const bgImageCSS = cx(styles.img_wrapper, {
        triangle_version: style !== StyleBannerEnum.FULL,
        triangle_main_color: style === StyleBannerEnum.TRIANGLE_WITHBG,
        img_wrapper_left: pictureAlign === HorizontalAlignEnum.LEFT,
        img_wrapper_right: pictureAlign === HorizontalAlignEnum.RIGHT,
    });

    const bgColoredCSS = cx(styles.db_colored_bg, {
        triangle_version: style !== StyleBannerEnum.FULL,
        db_colored_bg_left: pictureAlign === HorizontalAlignEnum.LEFT,
        db_colored_bg_right: pictureAlign === HorizontalAlignEnum.RIGHT,
    });

    const overImageContainerCSS = cx(styles.db_over_image_container, 'col-12', 'col-xl-6', 'order-0', {
        'order-xl-0': pictureAlign === HorizontalAlignEnum.LEFT,
        'order-xl-1': pictureAlign === HorizontalAlignEnum.RIGHT,
        db_over_image_container_left: pictureAlign === HorizontalAlignEnum.LEFT,
        db_over_image_container_right: pictureAlign === HorizontalAlignEnum.RIGHT,
    });

    return (
        <section id={id} className={styles.section}>
            {/* Background Element */}
            <div className={bgColoredCSS} style={{ backgroundColor }} />
            <div className={bgImageCSS} style={{ backgroundColor }}>
                <Pin.Cover xy={{ zIndex: -1 }}>
                    <Image objectFit="cover" layout="fill" image={picture} objectPosition="center center" className={styles.background_img} />
                </Pin.Cover>
            </div>

            {/* Content Element */}
            <div className="container">
                <div className={`row ${styles.db_over_colored_container}`}>
                    <div className={`col-12 col-xl-6 order-1 ${styles.db_content_wrapper}`} style={{ color: textColor }}>
                        {picto && (
                            <div className={styles.db_icon_wrapper}>
                                <Image objectFit="contain" objectPosition="left" layout="fill" image={picto} maxWidth={300} />
                            </div>
                        )}
                        {picture && (
                            <div className={styles.db_mobile_bgimg}>
                                <Image objectFit="cover" layout="fill" image={picture} objectPosition="center center" className={styles.background_again_img} />
                            </div>
                        )}
                        {prefix && (
                            <>
                                <Font.FuturaHeavy mobile="14" desktop="16" className={supraLabelClasses}>
                                    {prefix}
                                </Font.FuturaHeavy>
                                <Spacer custom="25" />
                            </>
                        )}
                        {title && (
                            <Font.FuturaHeavy
                                mobile="24"
                                desktop="36"
                                className={`lh-32 lh-d-50 ls-1 font-uppercase ${styles.db_title}`}
                                style={{ color: titleColor }}
                            >
                                {title}
                            </Font.FuturaHeavy>
                        )}
                        {content && (
                            <>
                                {title && <Spacer.size20 />}
                                <Font.FuturaBook mobile="14" desktop="16" className="lh-20 lh-d-24" as="div">
                                    <div
                                        className={`markdown ${styles.db_markdown} ${primaryColorIsDark ? 'primaryColorIsDark' : ''}`}
                                        dangerouslySetInnerHTML={{ __html: parseMarkdown(content) }}
                                    />
                                </Font.FuturaBook>
                            </>
                        )}
                        {link && linkPosition === LinkPositionEnum.BELOW_TEXT && (
                            <>
                                {(title || content) && <Spacer.size40 />}
                                <div className={styles.btn_bellow_text}>
                                    <Link.Button
                                        label={link.label}
                                        href={link.slug}
                                        color={buttonColor}
                                        bgcolor={buttonBgColor}
                                        followClassName={`${link.followClass}-ga`}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={overImageContainerCSS} style={{ color: textColor }}>
                        {link && linkPosition === LinkPositionEnum.OVER_IMAGE && (
                            <Link.Button
                                label={link.label}
                                href={link.slug}
                                color={buttonColor}
                                bgcolor={buttonBgColor}
                                followClassName={`${link.followClass}-ga`}
                            />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
